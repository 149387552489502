<template>
	<div class="content bought">
		<el-tabs type="border-card" stretch>
		  <el-tab-pane label="视频">
			  <div class="vod-box">
			  	<el-row :gutter="0" style="margin: 0 auto;" class="vod-box-row">
			  		<el-col :style="{padding:(index%2==0?'4px 4px 4px 0px':'4px 0px 4px 4px')}" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoData" :key="index" :offset="index > 0 ? 0 : 0">
			  			<el-card :body-style="{padding: '0px',position:'relative'}">
			  			<div style="position: relative;" @click="toVideo(item.videoId)">
			  				 <el-image class="image" fit="cover" :src="item.videoId.imgUrl" lazy>
			  					<div slot="placeholder" class="image-slot">
			  					  加载中<span class="dot">...</span>
			  					</div>
			  					<div slot="error" class="image-slot">
			  						加载失败...<i class="el-icon-picture-outline"></i>
			  					</div>
			  				  </el-image>
			  				  <div class="bfcs">{{item.videoId.num}}万次播放</div>
			  			  </div>
						  <div class="boughtDelet">
							  <el-popconfirm
								title="确定删除吗？"
								@confirm="deletteVideoAxios(item.videoId.id)">
								<i slot="reference" class="el-icon-delete"></i>
							  </el-popconfirm>
						  </div>
			  			  <div style="padding: 5px;" @click="toVideo(item.videoId)">
			  				<p class="name">{{item.videoId.name}}</p>
			  			  </div>
			  			</el-card>
			  		  </el-col>
			  	</el-row>
				<div class="ckgdsx">
					<el-button type="danger" @click="toHome">更多视频 ></el-button>
				</div>
			  </div>
		  </el-tab-pane>
		  <el-tab-pane label="小说">
			 <ul class="fictionList">
			    <li v-for="item in fictionListData" class="list-item" style="background: #F1F3F4;">
					<span class="fictionSpan" @click="toRead(item.fictionId)">
						<i class="el-icon-document"></i>
						{{item.fictionId.name}}
					</span>
					<div class="fictionScDiv">
					  <el-popconfirm
						title="确定删除吗？"
						@confirm="deletteFictionAxios(item.fictionId.id)">
						<i slot="reference" class="el-icon-delete"></i>
					  </el-popconfirm>
					</div>
			    </li>
			  </ul>
			  <div class="ckgdsx">
				<el-button type="danger" @click="toFiction">更多小说 ></el-button>
			  </div>
		  </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:'',
			videoData: [],
			fictionListData:[],
		  }
		},
		created() {
			if(localStorage.getItem('userId_ds')){
				this.userId = localStorage.getItem('userId_ds');
			}else{
				this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}
				}
			}
			this.myVideoAxios();
			this.myFictionAxios();
	    },
		methods: {
			toFiction(){
				this.$router.push({
				　　path: '/Fiction', 
				    query: { 
						userId:this.userId
					}
				})
			},
			toHome(){
				this.$router.push({
				　　path: '/Index', 
				    query: { 
						userId:this.userId
					}
				})
			},
			toRead(item){
			 this.$router.push({
				name: "Read",
				query: {
					userId:this.userId,
					fictionId:item.id
				}
			 });
			},
			toVideo(item){
				this.$router.push({
				　　path: '/Video', 
				    query: { 
						videoId: item.id,
						userId:this.userId,
						videoName:""
					}
				})
			},
			myVideoAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/video',params)
				  .then(function (response) {
					  var datas = response.data.data;
					   if(response.data.code==1){
						 that.videoData = datas;
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			myFictionAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/fiction',params)
				  .then(function (response) {
					  var datas = response.data.data;
					   if(response.data.code==1){
						 that.fictionListData = datas;
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			deletteVideoAxios(videoId){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				params.append('videoId', videoId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/videoDelete',params)
				  .then(function (response) {
					  var datas = response.data.data;
					   if(response.data.code==1){
						 that.successOpen("删除成功");
						 that.videoData = datas;
					  }else{
						  that.erreOpen("删除失败");
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			deletteFictionAxios(fictionIds){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				params.append('fictionId', fictionIds);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/fictionDelete',params)
				  .then(function (response) {
					  var datas = response.data.data;
					   if(response.data.code==1){
						 that.successOpen("删除成功");
						 that.fictionListData = datas;
					  }else{
						  that.erreOpen("删除失败");
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
			successOpen(centtext) {
			  this.$message({
				showClose: true,
				message: centtext,
				type: 'success',
				offset:150,
			  });
			},
			erreOpen(centtext) {
			  this.$message({
				showClose: true,
				message: centtext,
				type: 'error',
				offset:150,
			  });
			},
		}
	}
</script>

<style>
	.bought .el-tabs--border-card>.el-tabs__content {
		padding: 0px;
	    padding-top: 10px;
	}
	.ckgdsx{
		text-align: center;
		padding: 100px 0px;
	}
	.boughtDelet{
		display: inline-block;
	    position: absolute;
	    top: 0px;
	    right: 0px;
		color: #cccccc;
		padding: 5px;
	}
</style>
