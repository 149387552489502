<template>
	<div class="content">
		<ul class="fictionList">
		  <li v-for="item in fictionListData" class="list-item">
			  <span class="fictionSpan" @click="toRead(item)">
				<i class="el-icon-document"></i>
				{{item.name}}
			  </span>
			  <div class="fictionScDiv">
				  <span v-if="(item.imgUrl==1)" @click="yisoucangtips" style="color: #f14668;"><i class="el-icon-star-on"></i>已收藏</span>
				  <span v-else @click="saveFictionListAxios(item.id)"><i class="el-icon-star-off"></i>收藏</span>
			  </div>
		  </li>
		</ul>
		<div style="width: 100%; text-align: center;margin: 2em 0em;overflow: hidden;">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :current-page=pageNo
			  :page-size="20"
			  :total=count
			   @current-change="handleCurrentChange"
			>
			</el-pagination>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '../components/footer.vue'
export default{
	components:{
		Footer
	},
	data() {
	  return {
		  userId:"",
		  fictionId:"",
		  fictionListData:[],
		  fictionListData2:"",
		  count:0,
		  pageNo:1,
		  isFirstEnter:false, // 是否第一次进入，默认false
	  }
	},
	created() {
	 this.isFirstEnter=true;
	 // 只有第一次进入或者刷新页面后才会执行此钩子函数
	 // 使用keep-alive后（2+次）进入不会再执行此钩子函数
	},
	beforeRouteEnter(to, from, next) {
	  console.log(from.name);
	  // 路由导航钩子，此时还不能获取组件实例 `this`，所以无法在data中定义变量（利用vm除外）
	  // 参考 https://router.vuejs.org/zh-cn/advanced/navigation-guards.html
	  // 所以，利用路由元信息中的meta字段设置变量，方便在各个位置获取。这就是为什么在meta中定义isBack
	  // 参考 https://router.vuejs.org/zh-cn/advanced/meta.html
	  if(from.name=='Read'){
		  to.meta.isBack=true;
		  //判断是从哪个路由过来的，
		  //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
	  }
	  next();
	},
	activated() {
		if(localStorage.getItem('userId_ds')){
			this.userId = localStorage.getItem('userId_ds');
		}else{
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
		}
		this.fictionId = this.$route.query.fictionId;
	  if(!this.$route.meta.isBack || this.isFirstEnter){
		   // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
		   // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
		  this.fictionListAxios();
	   }
	   // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
	   this.$route.meta.isBack=false
	   // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
	   this.isFirstEnter=false;
	},
	methods: {
		//分页
		handleCurrentChange(val) {
		  this.pageNo = val;
		  this.fictionListAxios();
		},
		 toRead(item){
			 this.$router.push({
			 	name: "Read",
			 	query: {
			 		userId:this.userId,
			 		fictionId:item.id
			 	}
			 });
		 },
		 fictionListAxios(){
		 	var that = this;
			var params = new URLSearchParams();
			params.append('pageNo', that.pageNo);
			params.append('type', that.fictionId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/fiction/fictionList',params)
		 	  .then(function (response) {
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
					that.count = datas.count;
		 			that.fictionListData = datas.data;
		 		  }
				  that.getFictionListAxios();
				  document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
		 	  })
		 	  .catch(function (error) {
		 		console.log(error);
		 	  });
		 },
		 saveFictionListAxios(fictionListId){
		 	var that = this;
			var params = new URLSearchParams();
			params.append('userId', this.userId);
			params.append('fictionId', fictionListId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/fictionSave',params)
		 	  .then(function (response) {
		 		 if(response.data.code==1){
		 		 	that.open('收藏成功','右上角收藏中查看');
					that.getFictionListAxios();
		 		 }else{
		 		 	that.open('通知','参数丢失');
		 		 }
		 	  })
		 	  .catch(function (error) {
				that.open('通知','网络出错，请重试！');
		 		console.log(error);
		 	  });
		 },
		 yisoucangtips(){
		 	this.open('已收藏','右上角收藏中查看');
		 },
		 getFictionListAxios(){
		 	var that = this;
			var params = new URLSearchParams();
			params.append('userId', this.userId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/fiction',params)
		 	  .then(function (response) {
				   if(response.data.code==1){
						var datas = response.data.data;
						for (var n = 0; n < that.fictionListData.length; n++) {
							for (var i = 0; i < datas.length; i++) {
								if(that.fictionListData[n].id==datas[i].fictionId.id){
									that.fictionListData[n].imgUrl = '1';
									break;
								}
							}
						}
				  }
		 	  })
		 	  .catch(function (error) {
		 		console.log(error);
		 	  });
		 },
		 open(title,content) {
		 	this.$notify({
		 	  title: title,
		 	  message: content,
		 	  offset: 100
		 	});
		 },
	}
}
</script>

<style>
	.fictionList{
	   list-style-type: none;
	   margin: 0px;
	   padding: 0px;
	}
	.fictionList .list-item{
	   height: 50px;
	   background: #FFFFFF;
	   margin-top: 10px;
	   line-height: 50px;
	   padding-left: 5px;
	   cursor: pointer;
	   position: relative;
	}
	.fictionScDiv{
		display: inline-block;
		position: absolute;
		right: 20px;
		font-size: .75rem;
		color: #7a7a7a;
	}
	.fictionSpan{
		width: 80%;
	    position: absolute;
	}
</style>
