<template>
	<div class="content mp3Play">
		<el-alert
		    title="请勿相信有声小说的广告!"
		    type="warning"
		    show-icon>
		</el-alert>
		<div class="body_mp3Play">
			<p class="title_mp3Play">{{mp3PlayData.name}}</p>
			<audio id="mp3player" :src="mp3PlayData.mp3Url" :autoplay="autoplay" oncontextmenu="return false" controlslist="nodownload" controls></audio>
			<div v-if="soundId!=0" class="tj_mp3Play">
				<el-button @click="previousMp3">上一章</el-button>
				<el-button @click="backMp3">目录</el-button>
				<el-button @click="nextMp3">下一章</el-button>
			</div>
		</div>
		<p style="text-align: center;margin-top: 10px;">有些浏览器无法自动播放，请自行点击播放按钮</p>
		<el-dialog
		  :visible.sync="warningBl"
		  width="90%"
		  center
		  top="27vh"
		  :show-close=false
		  :close-on-click-modal=false>
		   <h3 style="text-align: center;padding: 25px;font-size: 1.2rem;">开通<span style="color: #f14668">VIP</span>观看全站</h3>
		   <span slot="footer" class="dialog-footer">
			   <el-button type="danger" @click="readBack" round><i class="el-icon-orange"></i> 我再看看</el-button>
			   <el-button type="warning" @click="toRecharge" round><i class="el-icon-unlock"></i> 开通VIP</el-button>
		  </span>
		</el-dialog>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '../components/footer.vue'
export default{
	components:{
		Footer
	},
	data() {
	  return {
		  userId:"",
		  mp3PlayData:"",
		  soundId:"",
		  mp3Id:"",
		  autoplay:true,
		  loading:"",
		  warningBl:false
	  }
	},
	created(){
		if(localStorage.getItem('userId_ds')){
			this.userId = localStorage.getItem('userId_ds');
		}else{
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
		}
		this.soundId = this.$route.query.soundId;
		this.mp3Id = this.$route.query.mp3Id;
		this.addNumAxios();
	},
	methods: {
		 mp3PlayAxios(){
		 	var that = this;
			var params = new URLSearchParams();
			params.append('type', that.soundId);
			if(that.soundId==0){
				params.append('id', that.mp3Id);
			}else{
				params.append('num', that.mp3Id);
			}
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/yousheng/getMp3',params)
		 	  .then(function (response) {
				  if(that.loading!=""){
					   that.loading.close();
				  }
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
		 			that.mp3PlayData = datas;
		 		  }else{
					that.mp3PlayOpen("暂无数据！");
				  }
		 	  })
		 	  .catch(function (error) {
				if(that.loading!=""){
				   that.loading.close();
				}
		 		console.log(error);
		 	  });
		 },
		 readBack(){
		 	this.$router.go(-1);
		 },
		 toRecharge(){
		 	this.$router.push({
		 	　　path: '/Recharge2',
		 	    query: {
		 			userId:this.userId
		 		}
		 	});
		 },
		 mp3PlayOpen(content) {
		 	this.$message({
		 	  message: content,
		 	  type: 'error',
		 	  offset:200
		 	});
		 },
		 previousMp3(){
			 if(this.mp3PlayData.num=='1'){
				 this.mp3PlayOpen("已是第一章");
				 return;
			 }
			 --this.mp3Id;
			 var that = this;
			 that.$router.push({
				 query:{
					 userId:that.userId,
					 soundId:that.soundId,
					 mp3Id:that.mp3Id
				 }
			 })
			 that.mp3PlayAxios();
		 },
		 backMp3(){ 
			 var that = this;
			 that.$router.push({
			 	name: "Mp3",
			 	query: {
			 		userId:that.userId,
			 		soundId:that.soundId
			 	}
			 });
		 },
		 nextMp3(){
			 ++this.mp3Id;
			 var that = this;
			 that.$router.push({
				 query:{
					 userId:that.userId,
					 soundId:that.soundId,
					 mp3Id:that.mp3Id
				 }
			 })
			 that.mp3PlayAxios();
		 },
		  addNumAxios(){
		 	var that = this;
		 	that.loading = this.$loading({
		 	  lock: true,
		 	  text: '拼命加载中...',
		 	  spinner: 'el-icon-loading',
		 	  background: 'rgba(0, 0, 0, 0.7)'
		 	});
		 	var params = new URLSearchParams();
		 	params.append('id', this.userId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/addNm',params)
		 	  .then(function (response) {
		 		  if(response.data.code==1){
		 			 var datas = response.data.data;
		 			 that.warningBl = true;
					 that.loading.close();
		 		  }else{
		 			  if(response.data.code==50003){
		 				  var datas = response.data.data;
		 				  if(datas.totalNum==1600000){
							  that.warningBl = false;
							  that.mp3PlayAxios();
		 				  }else if(datas.totalNum==16000001){
							  that.warningBl = false;
							  that.mp3PlayAxios();
						  }else{
							  that.warningBl = true;
							  that.loading.close();
						  }	
		 			  }else{
		 				  that.warningBl = true;
		 				  that.loading.close();
		 			  }
		 		  }
		 	  })
		 	  .catch(function (error) {
		 		  that.loading.close();
		 		  console.log(error);
		 	  });
		 }
	}
}
</script>

<style>
	#mp3player {
	    display: block;
	    margin: 0 auto!important;
	    width: 98%!important;
	}
	.mp3Play audio::-webkit-media-controls {
	　　overflow: hidden !important;
	}
	.mp3Play audio::-webkit-media-controls-enclosure {
	　　width: calc(100% + 32px);
	　　margin-left: auto;
	}
	.body_mp3Play{
		margin-top: 12%;
	}
	.title_mp3Play{
		font-size: 1.2rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 40px;
	}
	.tj_mp3Play{
		text-align: center;
		margin-top: 35px;
	}
</style>
