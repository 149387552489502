<template>
  <div class="qr-code-generator">
    <!-- <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="QR Code" /> -->
	<el-image v-if="qrCodeUrl" :src="qrCodeUrl">
	  <div slot="placeholder" class="image-slot">
		加载中<span class="dot">...</span>
	  </div>
	</el-image>
  </div>
</template>

<script>
export default {
	props: {
	   itemWidth: {
	       type: Number,
	       default: 220
	   },
	   itemHeight: {
	       type: Number,
	       default: 220
	   }
	},
  data() {
    return {
      qrCodeUrl: '',
    };
  },
  mounted() {
	 this.orcodeAxios();
  },
  methods: {
	  orcodeAxios(){
	  	var that = this;
		var params = new URLSearchParams();
		params.append('version', '4');
	  	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/orcode',params)
	  	  .then(function (response) {
	  		  var datas = response.data.data;
	  		  if(response.data.code==1){
				 that.qrCodeUrl = "https://api.qrserver.com/v1/create-qr-code/?size="+that.itemWidth+"x"+that.itemHeight+"&data=https://"+datas.address;
	  		  }
	  	  })
	  	  .catch(function (error) {
	  		  console.log(error);
	  	  });
	  },
    },
};
</script>

<style scoped>
.qr-code-generator canvas {
  width: 100%;
  height: auto;
  }
</style>
