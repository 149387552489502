<template>
	<div class="content">
		<div v-show="num!=0" class="player" id="player" style="border-radius: 10px;"></div>
		<div v-show="warningBl" class="players" id="players" style="border-radius: 10px;">
			<!-- <div>今日免费次数已用完，明日再来观看！</div> -->
			<div>查看完整视频可以分享解锁，也可以付费观看全站！</div>
			<el-button v-if="isDywut==0" type="warning" size="medium" @click="toMallInfo" round><i class="el-icon-unlock"></i> 分享3个群解锁所有视频</el-button>
			<el-button v-else type="warning" size="medium" @click="centerDialogVisible7=true" round><i class="el-icon-unlock"></i> 邀请2人解锁所有视频</el-button>
			<!-- <p v-if="isShare!=30" @click="toSwitch">已有账号？-登录</p> -->
			<el-button type="danger" @click="toRecharge2" round><i class="el-icon-unlock"></i> 打赏解锁</el-button>
		</div>
		<div class="vod-name">
			<p class="video_name">{{videoData.name}}</p>
			<div class="scjb">
				<p class="bfcs"><a href="javascript:void(0);">{{videoData.cidName}}</a><span> · {{videoData.num}}万次播放  · </span> <span @click="toQrcode" style="color: red;font-weight: bold;"> 本站二维码</span></p>
				<div class="shoucDiv">
					<div v-if="souc" @click="soucangcli"><i class="el-icon-star-off"></i><p style="color: #FF701A;">收藏</p></div>
					<div v-if="yisouc" @click="yisoucangtips" style="color: #FF701A;"><i class="el-icon-star-on"></i><p>已收藏</p></div>
				</div>
			</div>
		</div>
		<div class="warning">
			<p>查看完整视频可以分享解锁，也可付费观看全站哦!</p>
			<el-button v-if="isDywut==0" @click="toMallInfo" type="warning"><i class="el-icon-unlock"></i> 分享3个群解锁所有视频</el-button>
			<el-button v-else @click="centerDialogVisible7=true" type="warning"><i class="el-icon-unlock"></i> 邀请2人解锁所有视频</el-button>
			<el-button @click="toRecharge2" type="danger"><i class="el-icon-unlock"></i> 打赏解锁</el-button>
			<!-- <div v-if="isShare!=30" @click="toRecharge2">成为 VIP</div> -->
		</div>
		<div class="cnqh">
			<p style="font-weight: 700;padding-left: 5px;">猜你喜欢</p>
			<el-row :gutter="0" style="margin: 0 auto;overflow:auto;" class="vod-box-row infinite-list">
				<el-col class="videoCol" :style="{padding:(index%2==0?'4px 4px 4px 0px':'4px 0px 4px 4px')}" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoDataArr" :key="index" :offset="index > 0 ? 0 : 0">
					<el-card :body-style="{ padding: '0px' }">
					<div style="position: relative;"  @click="toVideo(item)">
						 <el-image class="image" fit="cover" :src="item.imgUrl" lazy>
							<div slot="placeholder" class="image-slot">
							  加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image-slot">
								加载失败...<i class="el-icon-picture-outline"></i>
							</div>
						  </el-image>
						  <div class="bfcs">{{item.num}}万次播放</div>
					  </div>
					  <div style="padding: 5px;"  @click="toVideo(item)">
						<p class="name">{{item.name}}</p>
					  </div>
					</el-card>
				  </el-col>
			</el-row>
			<p class="loadingP" v-show="vloading">玩命加载中...</p>
			<p class="loadingP" v-show="noMore">加载失败...<el-button @click="videoAxiosCnxh" size="mini" type="warning">点我重新加载</el-button></p>
		</div>
		<el-dialog
		  :visible.sync="centerDialogVisible"
		  width="90%"
		  top="20vh"
		  center>
		  <span slot="title" class="dialog-title">
		     分享到聊天群解锁所有视频
		  </span>
		  <div class="dialogBody">
			<p style="color: red;">分享成功后即可解锁所有视频</p>
			<p style="color: red;margin-top: 10px;">提示：点击复制你的推广链接并分享到QQ群或其它超过50人的群</p>
			<p style="font-size: 13px;margin-top: 20px;">{{$parent.shareCotent}}{{host}}</p>
		  </div>
		  <span slot="footer" class="dialog-footer">
			  <el-button style="width: 40%;" @click="toShareTj">分享检测</el-button>
		      <el-button type="primary" style="width: 40%;background: #FF701A;" @click="copyToClip">复制去分享</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="请修改账户密码,并保存好!"
		  :visible.sync="centerDialogVisible3"
		  width="75%"
		  top="25vh"
		  :close-on-press-escape="showClosex"
		  :before-close="handleClose"
		  center>
		  <div class="dialogBodyx">
			   <span style="font-size: 16px;color: red;font-weight: 700;line-height: 30px;"><p style="color: green;">账户因为切换网络有可能会生成新的账户</p>请记住自己充值过的账户密码，如果不能看了请切换回充值过的账户！</span>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="warning" @click="toModify()">去修改</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="提示"
		  :visible.sync="centerDialogVisible6"
		  width="80%"
		  top="25vh"
		  center>
		  <div class="dialogBodyx">
			   <span style="font-size: 16px;color: red;font-weight: 700;line-height: 30px;"><p style="color: red;">{{sths}}</p></span>
		  </div>
		  <span slot="footer" class="dialog-footer">
			<el-button v-if="shareId==3" type="danger" @click="toHome">去观看 >></el-button>
		    <el-button v-else type="warning" @click="toQuShare">去分享 >></el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  :visible.sync="centerDialogVisible7"
		  width="90%"
		  top="20vh"
		  center>
		  <span slot="title" class="dialog-title">
		     邀请2人解锁所有视频
		  </span>
		  <div class="dialogBody">
			<p style="font-size: 13px;">{{$parent.shareCotent}}{{host}}</p>
			<p style="color: red;margin-top: 25px;">提示：点击复制你的推广链接并分享到微信QQ群、抖音私信陌生人、微博贴吧博客等</p>
		  </div>
		  <span slot="footer" class="dialog-footer">
			  <el-button style="width: 40%;" @click="jcsfYq">分享检测</el-button>
		      <el-button type="primary" style="width: 40%;background: #FF701A;" @click="copyToClipZfz7">复制去分享</el-button>
		  </span>
		</el-dialog>
		<div v-show="fullscreenLoading" class="container">
		 <div class="loading"></div>
		</div>
	</div>
</template>

<script>
	let Hls = require('hls.js');
	import DPlayer from 'dplayer';
	export default{
		components:{
			DPlayer,
		},
		data() {
		  return {
			  userId:"",
			  videoId:"1348126734155866112",
			  videoName:"",
			  videoData:"",
			  num:0,
			  warningBl:false,
			  loading:"",
			  isFullscreen:0,
			  centerDialogVisible: false,
			  host:"520tantan.com",
			  uid:"2wexoq76",
			  browserType:'',
			  centerDialogVisible2:false,
			  centerDialogVisible3:false,
			  centerDialogVisible6:false,
			  centerDialogVisible7:false,
			  showClosex:false,
			  fullscreenLoading: false,
			  money:'9',
			  moneyStr:'',
			  videoDataArr: [],
			  vloading:false,
			  noMore:false,
			  count:0,
			  souc:true,
			  yisouc:false,
			  dqdzUrl:"",
			  ysData:"",
			  sths:"未检测到您的分享，请分享后尝试",
			  shareId:1,
			  isShare:0,
			  isDywut:0,
		  }
		},
		created(){
			document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
			if(localStorage.getItem('userId_ds')){
				this.userId = localStorage.getItem('userId_ds');
			}else{
				this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}
				}
			}
			this.host = "http://"+document.domain;
			var dqDomain = document.domain;
			this.videoId = this.$route.query.videoId;
			this.videoName = this.$route.query.videoName;
			this.addNumAxios();
		},
		/* beforeRouteEnter(to, from, next) {
		    next(vm => {
		      if (from.name == 'Bought') {
		        vm.num = 100001;
				vm.videoAxios();
		        return;
		      }else{
				  vm.addNumAxios();
				  return;
			  }
		    })
		}, */
		mounted () {
		    let that = this;
		    window.onscroll = function(){
		      //变量scrollTop是滚动条滚动时，距离顶部的距离
		      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
		      //变量windowHeight是可视区的高度
		      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
		      //变量scrollHeight是滚动条的总高度
		      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
		        //滚动条到底部的条件
		        if(parseInt(scrollTop+windowHeight) >= (scrollHeight-220)){
				//到了这个就可以进行业务逻辑加载后台数据了
				/* var zTop = scrollTop+windowHeight+200;
				document.body.scrollTop=document.documentElement.scrollTop=zTop; */
				  if(that.vloading==false){
					  that.videoAxiosCnxh(11);
				  }
				  that.vloading=true;
				  //console.log("到了底部");
		        }
			}
		},
		methods: {
			toMallInfo(){
				this.centerDialogVisible = true;
				setTimeout(() => {
					this.copyToClipZfz();
				}, 500);
			},
			toShareTj(){
				var that = this;
				that.loading = this.$loading({
				  lock: true,
				  text: '正在检测，请稍等...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/groupRecords/save',params)
				  .then(function (response) {
					setTimeout(() => {
						that.loading.close();
						that.centerDialogVisible = false;
						that.centerDialogVisible6 = true;
					}, 4000);
					  that.sths = response.data.message;
					  if(response.data.code==1){
						var datas = response.data.data;
						that.shareId = datas.steps;
					  }else if(response.data.code == 20001){
						var datas = response.data.data;
						that.shareId = datas.steps;
					  }
				  })
				  .catch(function (error) {
					  that.loading.close();
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			toQuShare(){
				this.centerDialogVisible6 = false;
				this.centerDialogVisible = true;
				setTimeout(() => {
					this.copyToClipZfz();
				}, 500);
			},
			toHome(){
				this.centerDialogVisible6 = false;
				this.centerDialogVisible7 = false;
				this.$router.push({
					name: "Index",
					query: {
						userId: this.userId,
					}
				});
			},
			toSwitch(){
				this.$router.push({
					name: "Switch",
					query: {
						userId:this.userId
					}
				});
			},
			toModify(){
				this.$router.push({
				　　path: '/Modify', 
				    query: {
						userId:this.userId,
						typePath:"video"
					}
				});
				this.centerDialogVisible3 = false;
			},
			toRecharge2(){
				this.$router.push({
				　　path: '/Recharge2', 
				    query: {
						userId:this.userId
					}
				});
			},
			toQrcode(){
				this.$router.push({
				　　path: '/Qrcode',
				});
			},
			handleClose(done) {
				this.centerDialogVisible3 = false;
				this.videoAxios();
			},
			videoAxios(){
				var that = this;
				/* that.loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				}); */
				var params = new URLSearchParams();
				params.append('id', this.videoId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/getVideo',params)
				  .then(function (response) {
					  that.loading.close();
					  if(response.data.code==1){
						 that.videoData = response.data.data;
						 that.money = that.videoData.money;
						 that.moneyStr = that.money+"元本片";
						 if(that.num != 0){
							 var sUserAgent = navigator.userAgent.toLowerCase();
							 const dp = new DPlayer({
							   element: document.getElementById('player'),
								autoplay: true,
								video: {
									url: that.videoData.videoUrl,
									pic: that.videoData.imgUrl,
									type: that.$parent.videoTypes,
									customType: {
										customHls: function (video, player) {
											const hls = new Hls();
											hls.loadSource(video.src);
											hls.attachMedia(video);
										},
									},
								},
							 });
						 }
					  }else{
						that.moneyStr = that.money+"元本片";
					  }
					  that.getSoucangcList();
					  that.videoAxiosCnxh();
				  })
				  .catch(function (error) {
					  that.moneyStr = that.money+"元本片";
					  that.loading.close();
					  console.log(error);
				  });
			 },
			addNumAxios(){
				var that = this;
				that.loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('id', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/addNm',params)
				  .then(function (response) {
					  that.domainAxiosVideo();
					  if(response.data.code==1){
						 var datas = response.data.data;
						 that.num = datas.num;
						 that.uid = datas.uid;
						 that.isShare = datas.isShare;
						 that.isDywut = datas.isDywut;
						 that.host = that.host+"/?uid="+that.uid;
						 that.userId = datas.id;
						 that.warningBl = false;
					  }else{
						  if(response.data.code==50003){
							  var datas = response.data.data;
							  that.uid = datas.uid;
							  that.isShare = datas.isShare;
							  that.isDywut = datas.isDywut;
							  that.host = that.host+"/?uid="+that.uid;
							  that.userId = datas.id;
							  if(datas.totalNum==1600000){
								   that.num = 10000;
								   that.warningBl = false;
							  }else if(datas.totalNum==16000001){
								  //提示充值过的用户修改账号密码
								  that.warningBl = false;
								  that.num = 10000;
								  that.centerDialogVisible3 = true;
								  that.loading.close();
							  }else{
								  that.num = 0;
								  that.warningBl = true;
								  that.loading.close();
							  }
						  }else{
							  that.num = 0;
							  that.warningBl = true;
							  that.loading.close();
						  }
					  }
					  if(!that.centerDialogVisible3){
						that.videoAxios();
					  }
				  })
				  .catch(function (error) {
					  that.loading.close();
					  console.log(error);
				  });
			},
			copyToClip() {
				var content = this.$parent.shareCotent+this.host;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.open("复制成功","分享到QQ群或者其它群聊");
				const qqUrl = 'mqq://';
      			window.location.href = qqUrl;
			},
			copyToClipZfz() {
				var content = this.$parent.shareCotent+this.host;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.open("复制成功","分享到QQ群或者其它群聊");
			},
			copyToClipZfz7() {
				var content = this.$parent.shareCotent+this.host;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.open("复制成功");
			},
			domainAxiosVideo(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userid', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/listWzff',params)
				  .then(function (response) {
					  if(response.data.code==1){
						var datas = response.data.data;
						that.host = datas;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			videoAxiosCnxh(index){
				var that = this;
				var params = new URLSearchParams();
				params.append('pageSize', "10");
				params.append('name', that.videoName);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/list',params)
				  .then(function (response) {
						if(response.data.code==1){
							var datas = response.data.data.data;
							that.count = response.data.data.count;
							if(index==undefined){
								//var yssjs = Math.round(Math.random());
								for(var i in datas){
								 /* if(i==yssjs){
									that.videoDataArr.push(that.ysData[i]);
								 } */
								 that.videoDataArr.push(datas[i]);
								}
							}else{
								for(var i in datas){
								 that.videoDataArr.push(datas[i]);
								}
							}
							 if(that.videoDataArr.length>=that.count){
							 	that.videoName = "";
							 }
							 that.vloading=false;
							 that.noMore=false;
						}else{
							that.vloading=false;
							that.noMore=true;
						}
				  })
				  .catch(function (error) {
					  that.vloading=false;
					  that.noMore=true;
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			soucangcli(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				params.append('videoId', this.videoId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/videoSave',params)
				  .then(function (response) {
						if(response.data.code==1){
							that.souc = false;
							that.yisouc = true;
							that.open('收藏成功','右上角收藏中查看');
						}else{
							that.open('通知','参数丢失');
						}
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			yisoucangtips(){
				this.open('已收藏','右上角收藏中查看');
			},
			jcsfYq(){
				var that = this;
				that.loading = this.$loading({
				  lock: true,
				  text: '正在检测，请稍等...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('id', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/recordListNew',params)
				  .then(function (response) {
						that.loading.close();
						if(response.data.code==1){
							var dqCount = response.data.data.count;
							that.zfbOpen('当前已邀请 '+dqCount+' 人');
							if(dqCount>=2){
								that.toHome();
							}
						}else{
							that.zfbOpen('您的邀请记录为0！');
						}
				  })
				  .catch(function (error) {
					  that.loading.close();
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			zfbOpen(content) {
				this.$message({
				  message: content,
				  type: 'success',
				  offset:300
				});
			},
			getSoucangcList(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userId', this.userId);
				params.append('videoId', this.videoId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/favorites/video',params)
				  .then(function (response) {
						if(response.data.code==1){
							that.souc = false;
							that.yisouc = true;
						}else{
							that.souc = true;
							that.yisouc = false;
						}
				  })
				  .catch(function (error) {
					  that.souc = true;
					  that.yisouc = false;
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			toVideo(item){
				//document.body.scrollTop=document.documentElement.scrollTop=0;
				if(item.id=="ysxs"){
					this.toSound();
					return;
				}
				this.videoId = item.id;
				const url = '/Video?videoId='+item.id+'&userId='+this.userId+'&videoName='+this.videoName;
				window.open(url, '_blank');
				/* this.addNumAxios();
				this.getSoucangcList(); */
			},
			toSound(){
				this.$router.push({
				　　path: '/Sound', 
				    query: { 
						userId:this.userId
					}
				})
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
			getBrowserInfo(){
				if(navigator.userAgent.toLowerCase().toString().indexOf('qqbrowser') > -1){
					this.browserType = "QQ";
				}
				if(navigator.userAgent.toLowerCase().toString().indexOf('baidu') > -1){
					this.browserType = "百度";
				}
			}
		}
	}
</script>

<style scoped>
	.player{
		max-width: 940px;
		margin: 1em auto;
	}
	@media screen and (min-width: 1024px){
		.player {
			height: 400px;
		}
	}
	@media screen and (max-width: 1215px){
		.player {
			height: 200px;
		}
	}
	.players{
		max-width: 940px;
		margin: 1em auto;
		background: #000;
		color: #fff;
		text-align: center;
	}
	.players .el-button{
		margin-top: 35px;
	}
	.players p{
		font-size: 12px;
	    color: #FF701A;
	    margin-top: 14px;
		text-decoration: underline;
	}
	@media screen and (min-width: 1024px){
		.players {
			height: 220px;
			padding-top: 180px;
		}
	}
	@media screen and (max-width: 1215px){
		.players {
			height: 130px;
			padding-top: 70px;
			padding-bottom: 10px;
		}
	}
	/* .players button{
		width: 60%;
	}
	.warning button{
		width: 60%;
	} */
	.vod-name{
		max-width: 940px;
		margin: 0 auto;
	}
	.vod-name .video_name{
		padding: 8px 0px;
		font-size: 14px;
		font-weight: bold;
		color: #363636;
	}
	.vod-name .scjb .bfcs{
		display: inline-block;
		font-size: .75rem;
	}
	.vod-name .scjb .bfcs span{
		font-size: .75rem;
		color: #7a7a7a;
	}
	.vod-name .scjb{
		position: relative;
	}
	.vod-name .scjb .shoucDiv{
		position: absolute;
		top: 0px;
		right: 10px;
		color: #7a7a7a;
	}
	.vod-name .scjb .shoucDiv div{
		display: inline-block;
		text-align: center;
		margin: 0px 8px;
		cursor: pointer;
		font-weight: 700;
	}
	.vod-name .scjb .shoucDiv div p{
		font-size: .75rem;
		display: inline-block;
	}
	.warning{
		text-align: center;
		max-width: 940px;
		margin: 1em auto;
		background: white;
		padding: 0.6em 1em;
		border-radius: 12px;
	}
	.vzdpDiv img{
		width: 100%;
		height: 100px;
		border-radius: 5px;
		margin: 15px 0px;
	}
	.warning p{
		font-size: .85rem;
		color: #4a4a4a;
		font-weight: 700;
	}
	.warning .el-button{
		margin-right: 10px;
		margin-top: 10px;
	}
	.warning div{
		color: #FF701A;
		font-size: 12px;
		padding: 5px 0px;
		text-decoration: underline;
	}
	.dialogBody{
		padding: 10px 10px;
	}
	.czdialogBody{
		padding: 10px 15px;
	}
	.czdialogBody .el-button{
		width: 100%;
		margin: 8px auto;
		/* background-image: linear-gradient(
		135deg
		, #F05F57 10%, #360940 100%); */
	}
	.czimg{
		width: 100%;
		height: 8.5rem;
	}
	.fxhyDiv{
		font-size: 0.8rem;
		height: 12px;
		padding-left: 5px;
		margin-bottom: 12px;
	}
</style>
