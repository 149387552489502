import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Video from '../views/video.vue'
import Search from '../views/search.vue'
import My from '../views/my.vue'
import Modify from '../views/modify.vue'
import Switch from '../views/switch.vue'
import Fiction from '../views/fiction.vue'
import FictionList from '../views/fictionList.vue'
import Read from '../views/read.vue'
import Recharge2 from '../views/recharge2.vue'
import Bought from '../views/bought.vue'
import Special from '../views/special.vue'
import Sound from '../views/sound.vue'
import Mp3 from '../views/mp3.vue'
import Mp3Play from '../views/mp3Play.vue'
import Zfss from '../views/zfss.vue'
import Join from '../views/join.vue'
import Promote from '../views/promote.vue'
import Popular from '../views/popular.vue'
import Qrcode from '../views/qrcode.vue'


Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
	name: 'Index',
    component: Index,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Index',
	name: 'Index',
    component: Index,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Video',
  	name: 'Video',
    component: Video,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Search',
  	name: 'Search',
    component: Search,
	meta: {
		keepAlive: true, //此组件需要被缓存
		isBack:false, //用于判断上一个页面是哪个
		showNavbar:true,
	}
  },
  {
    path: '/My',
  	name: 'My',
    component: My,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Modify',
  	name: 'Modify',
    component: Modify,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Switch',
  	name: 'Switch',
    component: Switch,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Fiction',
  	name: 'Fiction',
    component: Fiction,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/FictionList',
  	name: 'FictionList',
    component: FictionList,
	meta: {
		keepAlive: true, //此组件需要被缓存
		isBack:false, //用于判断上一个页面是哪个
		showNavbar:true,
	}
  },
  {
    path: '/Read',
  	name: 'Read',
    component: Read,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Recharge2',
  	name: 'Recharge2',
    component: Recharge2,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Bought',
  	name: 'Bought',
    component: Bought,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Special',
  	name: 'Special',
    component: Special,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Sound',
  	name: 'Sound',
    component: Sound,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Mp3',
  	name: 'Mp3',
    component: Mp3,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Mp3Play',
  	name: 'Mp3Play',
    component: Mp3Play,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Zfss',
  	name: 'Zfss',
    component: Zfss,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Join',
  	name: 'Join',
    component: Join,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Promote',
  	name: 'Promote',
    component: Promote,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Popular',
  	name: 'Popular',
    component: Popular,
	meta: {
		showNavbar:true,
	}
  },
  {
    path: '/Qrcode',
  	name: 'Qrcode',
    component: Qrcode,
  	meta: {
  		showNavbar:true,
  	}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
