<template>
	<div>
		<el-row :gutter="10" style="margin: 20px auto;" class="tags-box">
		  <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2" v-for="(item, index) in searchData" :key="index" :offset="index > 0 ? 0 : 0">
			<div class="grid-content" @click="toSearch(item.name)">
			<el-button type="warning" size="mini">{{item.name}}</el-button>
		  </div></el-col>
		</el-row>
		<div class="footer"><!-- 收藏防封地址：
			<el-link type="primary">91tantan.com</el-link> -->
		</div>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:"",
			searchData:[],
		  }
		},
		created(){
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			if(this.GLOBAL.bottomData=="" || this.GLOBAL.bottomData==null){
				this.searchAxios();
			}else{
				this.searchData = this.GLOBAL.bottomData;
			}
		},
		methods: {
			searchAxios(){
				var that = this;
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoType/searchListData')
				  .then(function (response) {
					  var datas = response.data.data;
					  if(response.data.code==1){
						 that.searchData = datas;
						 that.GLOBAL.bottomData = datas;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			toSearch(name){
				this.$router.push({
				　　path: '/Search', 
					 query: { 
						input1: name,
						userId:this.userId
					}
				});
			},
		}
	}
	
</script>

<style>
</style>
