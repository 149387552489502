<template>
		<!--content star -->
		<div class="content">
			<div class="vod-box">
				<ly-tab
					fixBottom
				    v-model="selectedId"
				    :items="items"
				    :options="options"
					@change="tabclick">
				</ly-tab>
				<el-row :gutter="0" style="margin: 0 auto;"  class="vod-box-row">
					<el-col class="videoCol" :style="{padding:(index%2==0?'4px 4px 4px 0px':'4px 0px 4px 4px')}" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoData" :key="index" :offset="index > 0 ? 0 : 0">
						<el-card :body-style="{ padding: '0px' }">
						<div style="position: relative;"  @click="toVideo(item)">
							 <el-image class="image" fit="cover" :src="item.imgUrl" lazy>
								<div slot="placeholder" class="image-slot">
								  加载中<span class="dot">...</span>
								</div>
								<div slot="error" class="image-slot">
									加载失败...<i class="el-icon-picture-outline"></i>
								</div>
							  </el-image>
							  <div class="bfcs">{{item.num}}万次播放</div>
						  </div>
						  <div style="padding: 5px;"  @click="toVideo(item)">
							<p class="name">{{item.name}}</p>
						  </div>
						</el-card>
					  </el-col>
				</el-row>	
				<p class="loadingP" v-show="vloading">玩命加载中...</p>
				<p class="loadingP" v-show="noMorex">加载失败...<el-button @click="videoAxios" size="mini" type="warning">点我重新加载</el-button></p>
			</div>
		</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:'',
		    videoData: [],
			selectedId: 0,
			items: [],
			options: {
				activeColor: "#FF701A"			// 可在这里指定labelKey为你数据里文字对应的字段#1d98bd
			},
			vloading:true,
			noMorex:false,
			videoName:"",
			count:0,
			ysData:"",
			videoType:"jd",
		  }
		},
		created(){
			var dqDomain = document.domain;
			this.tieleListAxios();
			this.videoAxios(50);
		},
		mounted () {
		     let that = this;
		    window.onscroll = function(){
		      //变量scrollTop是滚动条滚动时，距离顶部的距离
		      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
		      //变量windowHeight是可视区的高度
		      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
		      //变量scrollHeight是滚动条的总高度
		      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
		        //滚动条到底部的条件
		        if(parseInt(scrollTop+windowHeight) >= (scrollHeight-220)){
				//到了这个就可以进行业务逻辑加载后台数据了
				/* var zTop = scrollTop+windowHeight+200;
				document.body.scrollTop=document.documentElement.scrollTop=zTop; */
				  if(that.vloading==false){
					  that.videoAxios();
				  }
				  that.vloading=true;
		        }
			}
		},
		methods: {
			tabclick(item, index){
				if(index==this.items.length-1){
					this.toSound();
				} else if(index==this.items.length-2){
					this.toFiction();
				}else if(index==0){
					this.videoName="";
					this.videoType = "jd";
					this.videoAxios(index);
				}else{
					this.videoName=item.label;
					this.videoType = "";
					this.videoAxios(index);
				}
			},
			toFiction(){
				if(localStorage.getItem('userId_ds')){
					this.userId = localStorage.getItem('userId_ds');
				}else{
					this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
					if(this.userId==null || this.userId==""){
						if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
							this.userId = this.$parent.userId;
						}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
							this.userId = this.$route.query.userId;
						}
					}
				}
				this.$router.push({
				　　path: '/Fiction', 
				    query: { 
						userId:this.userId
					}
				})
			},
			toSound(){
				if(localStorage.getItem('userId_ds')){
					this.userId = localStorage.getItem('userId_ds');
				}else{
					this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
					if(this.userId==null || this.userId==""){
						if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
							this.userId = this.$parent.userId;
						}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
							this.userId = this.$route.query.userId;
						}
					}
				}
				this.$router.push({
				　　path: '/Sound', 
				    query: { 
						userId:this.userId
					}
				})
			},
			toVideo(item){
				if(localStorage.getItem('userId_ds')){
					this.userId = localStorage.getItem('userId_ds');
				}else{
					this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
					if(this.userId==null || this.userId==""){
						if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
							this.userId = this.$parent.userId;
						}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
							this.userId = this.$route.query.userId;
						}
					}
				}
				if(item.id=="ysxs"){
					this.toSound();
					return;
				}
				const url = '/Video?videoId='+item.id+'&userId='+this.userId+'&videoName='+this.videoName;
				window.open(url, '_blank');
				/* this.$router.push({
				　　path: '/Video', 
				    query: { 
						videoId: item.id,
						userId:this.userId,
						videoName:this.videoName
					}
				}) */
			},
			videoAxios(index){
				var that = this;
				var params = new URLSearchParams();
				params.append('name', that.videoName);
				params.append('type', that.videoType);
				params.append('pageSize', "12");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/list',params)
				  .then(function (response) {
						if(response.data.code==1){
							if(index!=undefined){
								that.videoData=[];
							}
							that.count = response.data.data.count;
							var datas = response.data.data.data;
							for(var i in datas){
								that.videoData.push(datas[i]);
							}
							 if(that.videoData.length>=that.count){
							 	that.videoName = "";
							 }
							 that.vloading=false;
							 that.noMorex=false;
						}else{
							that.vloading=false;
							that.noMorex=true;
						}
				  })
				  .catch(function (error) {
					  that.vloading=false;
					  that.noMorex=true;
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			tieleListAxios(){
				var that = this;
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoType/listData')
				  .then(function (response) {
						if(response.data.code==1){
							var datas = response.data.data;
							for (var i = 0; i < datas.length; i++) {
								that.items.push({"label":datas[i].name});
							}
						}
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			currencyFormat(num) {
			  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			}
		}
	}
</script>

<style>
	.type-box .el-col{
		 margin-top: .7em;
		 text-align: center;
	}
	.type-box .grid-content .el-button{
		width: 90%;
		background-color: #FF701A;	
		text-align: left;
		font-weight: bold;
		font-size: .8rem;
	}
	.type-box .grid-content .el-button p{
		display: inline-block;
		float: right;
		color: white;
	}
	.loadingP{
		text-align: center;
		padding: 15px 0px;
	}
</style>
