<template>
	<div class="content">
		<div class="jointopDiv">
			<p>正式招募合作伙伴，在家自己当老板!</p>
			<div class="joinLogin">
				<p>代理商登录专区</p>
				<div class="joinLoginInput">
					<span>账号 </span>
					<el-input v-model="loginUserName" placeholder="请输入账号"></el-input>
				</div>
				<div class="joinLoginInput">
					<span>密码 </span>
					<el-input v-model="loginPassWord" placeholder="请输入密码" show-password></el-input>
				</div>
				 <el-button type="warning" @click="loginAxiosDl">登录</el-button>
				 <div class="joinwyjm"><a href="#joinfool">我要加盟 <i class="el-icon-arrow-down"></i></a></div>
			</div>
		</div>
		<div class="joinCenter">
			<i class="el-icon-caret-bottom"></i>
			<p>代理好处看这里</p>
			<ul>
				<li>完全免费，自由职业</li>
				<li>在家躺着赚钱</li>
				<li>实现睡后收入</li>
				<li>一切服务由本站免费提供</li>
				<li>通过分享网址～就能马上轻松赚钱！</li>
			</ul>
		</div>
		<div id="joinfool" class="joinfool">
			<i class="el-icon-caret-bottom"></i>
			<p>填写加盟表格</p>
			<div class="joinfoolInput">
				<span>设定您的专属账号</span>
				<el-input v-model="reUserName" placeholder="手机号或者邮箱"></el-input>
			</div>
			<div class="joinfoolInput">
				<span>设定密码</span>
				<el-input v-model="rePassWord" placeholder="密码最少6个字符" show-password></el-input>
			</div>
			<div class="joinfoolInput">
				<span>确认密码</span>
				<el-input v-model="rePassWord2" placeholder="再次输入密码" show-password></el-input>
			</div>
			<el-button type="danger" @click="registerAxiosDl">提交</el-button>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:'',
			loginUserName:'',
			loginPassWord:'',
			reUserName:'',
			rePassWord:'',
			rePassWord2:'',
		  }
		},
		created() {
			this.userId = localStorage.getItem('tan_userId_dl');
			if(this.userId!=null && this.userId!=''){
				this.getIdAxiosDl();
			}
	    },
		methods: {
			toPromote(){
				this.$router.push({
					name: "Promote",
					query: {
						userId: this.userId,
					}
				});
			},
			getIdAxiosDl(){
				var that = this;
				var params = new URLSearchParams();
				params.append('id', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/getId',params)
				  .then(function (response) {
					  if(response.data.code==1){
						var datas = response.data.data;
						that.loginUserName = datas.name;
						that.loginPassWord = datas.password;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			loginAxiosDl(){
				var that = this;
				if(that.loginUserName==""){
					that.open("账号不能为空");
					return;
				}
				if(that.loginPassWord==""){
					that.open("密码不能为空");
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '代理登录中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('name', that.loginUserName);
				params.append('password', that.loginPassWord);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/login',params)
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
						 var datas = response.data.data;
						 that.userId = datas.id; 
						 localStorage.setItem("tan_userId_dl",datas.id);
						 that.toPromote();
					  }else{
						that.open(response.data.message);
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  console.log(error);
				  });
			},
			registerAxiosDl(){
				var that = this;
				if(!that.isPoneAvailable(that.reUserName)){
					that.open("账号格式错误，请使用手机号或者邮箱");
					return;
				}
				if(that.rePassWord.length<6){
					that.open("密码长度最小6个字符");
					return;
				}
				if(that.rePassWord!==that.rePassWord2){
					that.open("2次密码不相同，请重新设置");
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '代理注册中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('name', that.reUserName);
				params.append('password', that.rePassWord);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/register',params)
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
						 var datas = response.data.data;
						 that.userId = datas.id; 
						 localStorage.setItem("tan_userId_dl",datas.id);
						 document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
						 that.toPromote();
					  }else{
						that.open(response.data.message);
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  console.log(error);
				  });
			},
			open(content) {
				this.$message({
				  showClose: true,
				  message: content,
				  type: 'error',
				  offset:200,
				  duration:3000,
				});
			},
			 // 判断是否为手机号或者邮箱
			isPoneAvailable (pone){
			 var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			 var myreg2 = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			 if (myreg.test(pone) || myreg2.test(pone)){
					return true;
				}else{
					return false;
				}
			},
		}
	}
</script>

<style>
	.jointopDiv{
		background: rgba(247,69,118,.8);
		color: white;
		text-align: center;
		padding: 15px 5px;
	}
	.jointopDiv >p{
		font-size: 1.2rem;
	}
	.joinLogin >p{
		font-size: 2rem;
		margin-top: 15px;
	}
	.joinLogin input{
		background: rgba(247,69,118,.8);
		width: 70%;
		height: 50px;
		color: white;
		font-size: 1rem;
		border: 1px solid white;
	}
	.joinLogin .el-input{
		display: inline;
	}
	.joinLoginInput>span{
		font-size: 1.2rem;
	}
	.joinLoginInput{
		margin: 20px 0px;
	}
	.joinLogin button{
		width: 85%;
	    background: #F7EF8D;
	    color: #EE4372;
	    font-size: 1.2rem;
	    font-weight: bold;
		margin-bottom: 17px;
		height: 50px;
	}
	.joinwyjm{
		padding-bottom: 5px;
	}
	.joinwyjm a{
		color: white;
		font-size: 1.3rem;
	}
	.joinCenter,.joinfool{
		background: white;
		border-top: 6px solid #ff8c95;
		margin-top: 1px;
		padding-bottom: 20px;
	}
	.joinCenter p{
		text-align: center;
		font-size: 1.2rem;
		margin-top: 10px;
	}
	.joinCenter ul li{
		font-weight: 700;
	    line-height: 27px;
	    color: #f74576;
	}
	.joinCenter i{
		margin: -11px auto;
	    display: table;
	    color: #f74576;
	    font-size: 32px;
	}
	.joinfool{
		padding: 0px 5px 100px 5px;
	}
	.joinfool p{
		text-align: center;
		font-size: 1.2rem;
		margin: 10px 0px;
		font-weight: bold;
	}
	.joinfool i{
		margin: -11px auto;
	    display: table;
	    color: #f74576;
	    font-size: 32px;
	}
	.joinfool .joinfoolInput{
		margin: 10px 0px 20px 0px;
	}
	.joinfool .joinfoolInput input{
		height: 50px;
	    font-size: 1.1rem;
		border: 1px solid #999;
	}
	.joinfool button{
		width: 90%;
	    margin: 55px auto;
	    display: inherit;
	    height: 50px;
	    font-size: 1.2rem;
	}
</style>
