<template>
	<!--content star -->
	<div class="content">
		<h2 class="switchH2">切换账户</h2>
		<div class="operate-box">
			<el-input
			  placeholder="账户"
			  v-model="userName"
			  clearable
			  minlength=3
			  maxlength=19>
			</el-input>
			<el-input
			  placeholder="密码"
			  v-model="paw"
			  clearable
			  minlength=3
			  maxlength=19>
			</el-input>
			<div>
				<el-button type="warning" style="font-weight: 700;" size="small" @click="switchAxios" plain>登录</el-button>
				<el-button type="warning" @click="toMallInfo" size="small" plain>取消</el-button>
			</div>
		</div>
		<Bottom></Bottom>
	</div>
	<!--content end -->
</template>

<script>
	import Bottom from '../components/bottom.vue'
	export default{
		components:{
			Bottom
		},
		data() {
		  return {
			userName:'',
			paw:'',
			userId:"",
		  }
		},
		created(){
			if(localStorage.getItem('userId_ds')){
				this.userId = localStorage.getItem('userId_ds');
			}else{
				this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}
				}
			}
		},
		methods: {
			toMallInfo(){
				this.$router.push({
					name: "My",
					query: {
						userId:this.userId
					}
				});
			},
			toHome(){
				this.$router.push({
					name: "Index",
					query: {
						userId:this.userId
					}
				});
			},
			switchAxios(){
				var that = this;
				if(this.userName.length<=0){
					this.open("通知","账户不能为空");
					return;
				}
				if(this.paw.length<=0){
					this.open("通知","密码不能为空");
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在登录中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('name', this.userName);
				params.append('password', this.paw);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/switchUsers',params)
				  .then(function (response) {
					  loading.close();
					  var datas = response.data.data;
					  if(response.data.code==1){
						 that.userId = datas.id;
						 that.$parent.userId = datas.id;
						 localStorage.setItem("username",datas.name);
						 localStorage.setItem("password",datas.password);
						 localStorage.setItem("userId_ds",datas.id);
						 that.toHome();
					  }else{
						  that.open('通知',response.data.message);
					  }
				  })
				  .catch(function (error) {
					  loading.close();
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
		}
	}
</script>

<style>
	.switchH2{
	   text-align: center;
	   color: #7a7a7a;
	}
	.operate-box{
	   max-width: 940px;
	   margin: 0 auto;
	   background: white;
	   padding: 25px 15px;
	   border-radius: 10px;
	   margin-bottom: 20px;
	}
	.operate-box div{
	   margin-top: 20px;
	   text-align: center;
	}
</style>
