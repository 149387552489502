<template>
	<!--content star -->
	<div class="content">
		<div class="contentTow">
			<div class="user-box">
				<div>
					<div class="icon">{{iconText}}</div>
					<div class="user-info">
						<p class="user-name">账号：{{username}}</p>
						<p class="user-pwd">密码：{{password}}</p>
						<div class="buttons">
							<el-button type="warning" @click="toModify" size="mini" plain>修改信息</el-button>
							<el-button type="warning" @click="toSwitch" size="mini" plain>切换账户</el-button>
						</div>
					</div>
				</div>
				<p class="user-info-tips">请务必<span style="color: #FF701A;font-weight: bold;font-size: 1rem;"> 截图 </span>保存好账号密码，或修改你的登录信息</p>
				<p v-if="$parent.zfssIndex==1" class="user-info-tips" style="margin: 0px;">{{$parent.zfssTitlex}}<a style="font-size: 18px;" @click="toZfss" href="javascript:void(0);">点击此处</a></p>
			</div>
			<div v-if="isVip==1" class="user-tips-vip">
				<h3 style="margin: 0px;">恭喜您成为VIP：到期时间</h3>
				<h4 style="color: #FF701A;">{{userData.maturityDates}}</h4>
			</div>
			<div v-else class="user-tips-vip">
				<h3 style="margin: 0px;">普通用户</h3>
			</div>
			<div class="user-tips-vip">
				<h3>请截屏保存本站二维码到相册，下次访问无忧！</h3>
				<QRCodeGenerator :itemWidth="130" :itemHeight="130" />
				<p style="text-align: left;margin-top: 0.9375rem;">使用手机浏览器或其他浏览器扫描二维码，即可跳转到最新域名！<span style="color: #FF701A;">(请勿使用微信扫码)</span></p>
			</div>
		</div>
		<!-- <Bottom></Bottom> -->
	</div>
	<!--content end -->
</template>

<script>
	import QRCodeGenerator from '../components/QRCodeGenerator.vue';
	export default{
		components:{
			QRCodeGenerator,
		},
		data() {
		  return {
			userId:"",
			username:"",
			password:"",
			frequency:0,
			num:0,
			totalNum:0,
			isVip:0,
			uid:"",
			host:"520tantan.com",
			dayCount:0,
			resultList:[],
			iconText:"1",
			userData:[],
		 }
		},
		created(){
			document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
			if(localStorage.getItem('userId_ds')){
				this.userId = localStorage.getItem('userId_ds');
			}else{
				this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}
				}
			}
			this.host = "http://"+document.domain;
			const savedUsername = localStorage.getItem('username');
			const savedPassword = localStorage.getItem('password');
			if (savedUsername && savedPassword) {
			      this.username = savedUsername;
			      this.password = savedPassword;
			}
			this.loginAxios();
		},
		methods: {
			toModify(){
				this.$router.push({
					name: "Modify",
					query: {
						userId:this.userId,
						typePath:'my'
					}
				});
			},
			toSwitch(){
				this.$router.push({
					name: "Switch",
					query: {
						userId:this.userId
					}
				});
			},
			toZfss(){
				this.$router.push({
					name: "Zfss",
					query: {
						userId:this.userId
					}
				});
			},
			loginAxios(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('name', this.username);
				params.append('password', this.password);
				params.append('type', "2");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/login',params)
				  .then(function (response) {
					  loading.close();
					  var datas = response.data.data;
					  that.userData = datas;
					  if(response.data.code==1){
						 that.userId = datas.id;
						 that.name = datas.name;
						 that.uid = datas.uid;
						 that.password = datas.password;
						 that.num = datas.num;
						 that.isVip = datas.isVip;
						 that.maturityDate = datas.maturityDate;
						 localStorage.setItem("username",datas.name);
						 localStorage.setItem("password",datas.password);
						 localStorage.setItem("userId_ds",datas.id);
						 that.iconText = datas.name.substr(0,1);
					  }else{
						  that.open('通知','网络出错，请重试！');
					  }
				  })
				  .catch(function (error) {
					loading.close();
					console.log(error);
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
		}
	}
</script>

<style>
.contentTow{
	max-width: 940px;
	margin: 0 auto;
}
.user-box{
   width: 100%;
   background: white;
   border-radius: 10px;
   text-align: center;
   padding: 12px 0px;
   position: relative;
}
.user-box .icon{
   width: 70px;
   height: 70px;
   border-radius: 50%;
   font-size: 2rem;
   display: inline-block;
   background-color: #FF701A;
   border-color: transparent;
   color: #fff;
   cursor: pointer;
   text-align: center;
   line-height: 70px;
}
.user-box .user-info{
	display: inline-block;
	text-align: left;
	margin-left: 20px;
	position: relative;
	top: 10px;
}
.user-box .user-info p{
	font-size: 14px;
	font-weight: bold;
	color: #4a4a4a;
}
 .user-box .user-info .buttons{
	margin-top: 5px;
 }
.user-box .user-info .buttons .el-button{
	 border: 2px solid #FF701A;
	 background: white;
	 font-weight: 700;
}
.user-box .user-info .buttons .el-button:hover{
	background-color: #FF701A;
}
.user-tips .el-button{
	border: 2px solid #FF701A;
	background: white;
	font-weight: 700;
}
.user-box .user-info-tips{
	font-size: .75rem;
	color: #4a4a4a;
	font-weight: bold;
	margin-top: 1em;
}
#levels{
	width: 100%;
	background: white;
	border-radius: 10px;
	text-align: center;
	padding: 10px 0px;
	margin-top: 10px;
}
#levels .grid-content .heading{
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 1px;
	margin-bottom: 10px;
}
#levels .grid-content .title{
	color: #363636;
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.125;
}
.user-tips,.user-tips2,.user-tips-vip{
	width: 100%;
	background: white;
	border-radius: 10px;
	text-align: center;
	padding: 10px 0px;
	margin-top: 10px;
}
.user-tips p{
	font-size: 14px;
	font-weight: 700;
	color: #4a4a4a;
}
.user-tips .el-button{
	margin-top: 1em;
}
.user-tips2 p{
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	color: #4a4a4a;
	line-height: 1.7em;
}
.user-tips3{
	background: white;
	border-radius: 10px;
	padding: 10px 5px;
	margin-top: 10px;
	margin-bottom: 20px;
}
.user-tips3>p{
	font-size: 14px;
	color: #4a4a4a;
	line-height: 1.5;
	font-weight: 700;
}
.user-tips3>p>span{
	font-size: .75rem;
	font-weight: inherit;
}
.user-tips3-head{
	color: #363636;
	font-size: .75rem;
	font-weight: 700;
	margin-top: 15px;
}
.user-tips3-head p{
	padding: .25em .5em;
}
.el-divider--horizontal{
	margin: 24px 0px 10px 0px;
}
.yqjl p{
	color: #7a7a7a;
	padding: .25em .5em;
	font-size: .75rem;
}
.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 30px;
	margin: 0;
  }
</style>
