import Vue from 'vue'
import App from './App.vue'
import router from './router'
import global_ from './components/Global'
import './plugins/element.js'
import '../public/css/public.css'
import Axios from 'axios';
import LyTab from 'ly-tab';

Vue.use(LyTab);


Vue.prototype.axios = Axios;
Vue.prototype.GLOBAL = global_//挂载到Vue实例上面
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
