<template>
	<div class="content">
		<div class="vod-box">
			<el-row :gutter="0" style="margin: 0 auto;" class="vod-box-row">
				<el-col class="videoCol" :style="{padding:(index%2==0?'4px 4px 4px 0px':'4px 0px 4px 4px')}" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoData" :key="index" :offset="index > 0 ? 0 : 0">
					<el-card :body-style="{ padding: '0px' }" >
					<div style="position: relative;" @click="toVideo(item)">
						 <el-image class="image" fit="cover" :src="item.imgUrl" lazy>
							<div slot="placeholder" class="image-slot">
							  加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image-slot">
								加载失败...<i class="el-icon-picture-outline"></i>
							</div>
						  </el-image>
						  <div class="bfcs">{{item.num}}万次播放</div>
					  </div>
					  <div style="padding: 5px;" @click="toVideo(item)">
						<p class="name">{{item.name}}</p>
					  </div>
					</el-card>
				  </el-col>
			</el-row>
		</div>
		<div style="width: 100%; text-align: center;margin: 2em 0em;overflow: hidden;">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :current-page=pageNo
			  :page-size="16"
			  :total=count
			   @current-change="handleCurrentChange"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			inputName:'',
			userId:"",
			videoData:[],
			count:0,
			pageNo:1,
			backInputName:"rfa$^",
			isFirstEnter:false // 是否第一次进入，默认false
		  }
		},
		watch: {
		  $route(to,from){
			  if(this.$route.meta.isBack && from.name=='Video'){
				  this.inputName = "";
				  this.inputName= this.$route.query.input1;
				  this.$parent.input1 = this.$route.query.input1;
			  }
			  if(!this.$route.meta.isBack && to.path=='/Search'){
				  this.inputName = "";
				  this.inputName= this.$route.query.input1;
				  this.$parent.input1 = this.$route.query.input1;
			  }
		  },
		  inputName() {
			this.pageNo = 1;
			this.videoAxios();
		  }
		},
		created(){
			this.isFirstEnter=true;
		},
		beforeRouteEnter(to, from, next) {
		  console.log(from.name);
		  // 路由导航钩子，此时还不能获取组件实例 `this`，所以无法在data中定义变量（利用vm除外）
		  // 参考 https://router.vuejs.org/zh-cn/advanced/navigation-guards.html
		  // 所以，利用路由元信息中的meta字段设置变量，方便在各个位置获取。这就是为什么在meta中定义isBack
		  // 参考 https://router.vuejs.org/zh-cn/advanced/meta.html
		  if(from.name=='Video'){
			  to.meta.isBack=true;
			  //判断是从哪个路由过来的，
			  //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
		  }
		  next();
		},
		activated() {
			if(localStorage.getItem('userId_ds')){
				this.userId = localStorage.getItem('userId_ds');
			}else{
				this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}
				}
			}
		  if(!this.$route.meta.isBack || this.isFirstEnter){
			   // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
			   // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
			   this.inputName = "";
			   this.inputName = this.$route.query.input1==undefined?this.$parent.input1:this.$route.query.input1;
			   this.$parent.input1 = this.$route.query.input1;
		   }
		   // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
		   this.$route.meta.isBack=false
		   // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
		   this.isFirstEnter=false;
		},
		methods: {
			//分页
			  handleCurrentChange(val) {
				this.pageNo = val;
				this.videoAxios();
			  },
			 toVideo(item){
				this.$router.push({
				　　path: '/Video', 
				    query: { 
						videoId: item.id,
						userId:this.userId,
						videoName:this.inputName
					}
				})
			 },
			 videoAxios(){
			 	var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
			 	var params = new URLSearchParams();
			 	params.append('name', that.inputName);
				params.append('pageNo', that.pageNo);
			 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/list',params)
			 	  .then(function (response) {
					  loading.close();
			 		  var datas = response.data.data.data;
			 		  if(response.data.code==1){
						 /* if(that.pageNo==1){
							  if(that.count==0){
								that.open("搜索结果","共"+response.data.data.count+"条数据");
							  }
						 } */
						 that.count = response.data.data.count;
			 			 that.videoData = datas;
						 document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
			 		  }else{
						 that.open("搜索结果","暂无数据"); 
					  }
			 	  })
			 	  .catch(function (error) {
					  that.open("搜索结果","暂无数据"); 
					  loading.close();
			 	  });
			 },
			 open(title,content) {
			 	this.$notify({
			 	  title: title,
			 	  message: content,
			 	  offset: 100
			 	});
			 }
		}
	}
</script>

<style>
	/* .headDiv,.searchDiv{
		display: none;
	} */
</style>