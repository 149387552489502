<script>
const serverSrc='http://87.67.255.80:8080/tantan/';
const userId='12345678';
const host="tantannn.com";
const seaechName="";
const bottomData="";
const domainData="";
  export default
  {
    seaechName,//用户地址
    userId,//用户id
    serverSrc,//服务器地址
    host,//域名
	bottomData,
	domainData,
  }
</script>

