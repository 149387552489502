<template>
	<div class="content" style="margin-bottom: 40px;">
		<!-- <el-alert
		    title="客服联系方式已变更,请刷新页面获取最新联系方式"
		    type="warning"
		    show-icon>
		</el-alert> -->
		<div class="promoteTop">
			<p>当前余额 <span style="float: right;" @click="allFData">刷新</span></p>
			<div class="yue"><span>{{numFilter(userDlData.balance)}}</span></div>
			<el-row :gutter="0">
				<el-col class="tjes" :xs="9" :sm="9" :md="9" :lg="9" :xl="9">
					<p>今日推广人数:{{daytreData.dayNum}}</p>
					<p>昨日推广人数:{{daytreData.toDayNum}}</p>
					<p>累计推广人数:{{userDlData.totalNum}}</p>
				</el-col>
				<el-col class="txfx" :xs="15" :sm="15" :md="15" :lg="15" :xl="15">
					<el-button type="warning" @click="dltixGet">点击提现</el-button>
					<el-button type="danger" @click="copyToClip">推广分享</el-button>
				</el-col>
			</el-row>
		</div>
		<div class="promoteDlsy">
			<p>今日收益 <span>￥{{numFilter(daytreData.dayAmtNum)}}</span></p>
			<p>昨日收益 <span>￥{{numFilter(daytreData.toDayAmtNum)}}</span></p>
			<p>累计收益 <span>￥{{numFilter(userDlData.totalAmt)}}</span></p>
		</div>
		<p style="margin: 10px 0px;color:#EE4372;font-weight: bold;font-size: 1.2rem;">活动规则</p>
		<div class="promotefxhs">
			<p>邀请1人立即获得{{userDlData.amt}}元</p>
			<p>{{$parent.shareCotent}}{{userDlData.dltglj}}</p>
			<p>提示：点击复制你的推广链接并分享到微信QQ群、抖音私信陌生人、微博贴吧博客等</p>
			<p>有人点击访问时你的账户马上增加收益</p>
			<el-button type="danger" @click="copyToClip">复制</el-button>
		</div>
		<p style="margin: 10px 0px;color:#EE4372;font-weight: bold;font-size: 1.2rem;">代理升级规则</p>
		<div class="promoteFool">
			<el-row :gutter="10">
				<el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
					代理级别
				</el-col>
				<el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
					代理规则
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
					普通代理
				</el-col>
				<el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
					推广≤100人  每人1元
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
					高级代理
				</el-col>
				<el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
					推广≥200人 之后每人1.2元
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
					特约代理
				</el-col>
				<el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
					推广≥3000人 之后每人1.5元
				</el-col>
			</el-row>
			<div style="text-align: center;font-weight: bold;">当前代理级别： 
				<span style="color: #EE4372;" v-if="userDlData.level==1">普通代理 {{userDlData.amt}}元/人</span>
				<span style="color: #EE4372;" v-if="userDlData.level==2">高级代理 {{userDlData.amt}}元</span>
				<span style="color: #EE4372;" v-if="userDlData.level==3">特约代理 {{userDlData.amt}}元</span>
			</div>
			<!-- <div style="text-align: center;color: #EE4372;font-weight: bold;">每日早晨8点 升级代理级别</div> -->
		</div>
		<p style="margin: 10px 0px;color:#EE4372;font-weight: bold;font-size: 1.2rem;">今日邀请记录</p>
		<div class="yqjlPromotes">
			<el-row class="yqjlPromotesTitle">
			  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
				  邀请用户
			  </el-col>
			  <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
				  设备
			  </el-col>
			  <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
				  价格
			  </el-col>
			  <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
				  时间
			  </el-col>
			</el-row>
			<el-row class="yqjlPromotesData" v-for="(item, index) in daytreDatajl" :key="index">
			  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
				  {{item.coverUserId.name}}
			  </el-col>
			  <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
				  {{item.name}}
			  </el-col>
			  <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
				  {{numFilter(item.amt)}}
			  </el-col>
			  <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
				{{item.nyrString}}
			  </el-col>
			</el-row>
		</div>
		<el-dialog
		  :visible.sync="centerDialogVisibleProm"
		  width="90%"
		  top="20vh"
		  center>
		  <span slot="title" class="dialog-title">
		     联系客服提现
		  </span>
		  <div class="dialogBodyPron">
			 <p style="font-weight: 700;color:#EE4372;text-align: center;font-size: 1.2rem;">{{$parent.lxfsqq}}</p><br/> 
			 <p style="font-weight: 700;text-align: center;font-size: 1.2rem;">{{$parent.lxfsgmail}}:{{$parent.lxfsgmailNumber}}</p><br/>
			 <p style="font-weight: 700;text-align: center;font-size: 1.2rem;">{{$parent.lxfstele}}:{{$parent.lxfsTeleNumber}}</p>
		  </div>
		  <span slot="footer" class="dialog-footer">
			  <el-button type="primary" style="background: #f14668;" @click="copyToClipQQ($parent.lxfsTeleNumber)">复制{{$parent.lxfstele}}</el-button>
			  <el-button type="primary" style="background: #f14668;" @click="copyToClipQQ($parent.lxfsgmailNumber)">复制{{$parent.lxfsgmail}}</el-button>
		      
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:'',
			userDlData:'',
			daytreData:'',
			daytreDatajl:'',
			host:'',
			centerDialogVisibleProm:false,
		  }
		},
		created() {
			document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
			this.userId = this.$route.query.userId;
			if(this.userId==null || this.userId==''){
				this.userId = localStorage.getItem('tan_userId_dl');
				if(this.userId==null || this.userId==''){
					this.$parent.toJoin();
				}
			}
			this.host = "http://"+document.domain;
			this.getIdAxiosDl();
			this.dayRecordAxiosDl();
			this.dayRecordAxiosDlData();
	    },
		methods: {
			//刷新数据
			allFData(){
				this.getIdAxiosDl();
				this.dayRecordAxiosDl();
				this.dayRecordAxiosDlData();
				this.$parent.getWfzftsAxios();
			},
			//代理提现
			dltixGet(){
				if(this.userDlData.balance>=200){
					this.centerDialogVisibleProm=true;
				}else{
					this.open("余额大于200即可提现(支持 支付宝、银行卡、USDT)");
				}
			},
			// 截取当前数据到小数点后两位
			numFilter(value) {
			  const realVal = parseFloat(value).toFixed(2);
			  return realVal;
			},
			getIdAxiosDl(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '正在加载...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('id', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/getId',params)
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
						var datas = response.data.data;
						that.userDlData = datas;
						//that.domainAxiosPromote();
					  }else{
						that.open(response.data.message);
						that.$parent.toJoin();
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  console.log(error);
				  });
			},
			dayRecordAxiosDl(){
				var that = this;
				var params = new URLSearchParams();
				params.append('actingId', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/dayRecord',params)
				  .then(function (response) {
					  if(response.data.code==1){
						var datas = response.data.data;
						that.daytreData = datas;
					  }else{
						that.open("今日数据读取失败，请刷新尝试");
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			dayRecordAxiosDlData(){
				var that = this;
				var params = new URLSearchParams();
				params.append('actingId', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/join/dayRecordData',params)
				  .then(function (response) {
					  if(response.data.code==1){
						var datas = response.data.data;
						that.daytreDatajl = datas;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			domainAxiosPromote(){
				var that = this;
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/listWzff')
				  .then(function (response) {
					if(response.data.code==1){
						var datas = response.data.data;
						that.host = datas+"/?uids="+that.userDlData.uid;
					}else{
						that.host = that.host+"/?uids="+that.userDlData.uid;
					}
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			copyToClip() {
				//防封地址
				var content = this.$parent.shareCotent+this.userDlData.dltglj;
				//var content = this.$parent.shareCotent+this.host;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.openCbl("复制成功","分享到微信QQ群、抖音快手私信陌生人");
			},
			copyToClipQQ(cennum) {
				var content = cennum;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.openCbl("复制成功","请联系客服提现");
			},
			open(content) {
				this.$message({
				  showClose: true,
				  message: content,
				  type: 'error',
				  offset:200,
				  duration:3000,
				});
			},
			openCbl(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
		}
	}
</script>

<style>
	.promoteTop{
		background: white;
		border-radius: 5px;
		padding: 10px;
	}
	.promoteTop .yue{
		height: 100px;
		text-align: center;
		line-height: 100px;
		font-size: 2.2rem;
		color: #EE4372;
		font-weight: bold;
	}
	.promoteTop .txfx{
		text-align: right;
		margin-top: 10px;
	}
	.promoteTop .tjes{
		font-size: 14px;
	}
	.promoteDlsy p{
		padding: 10px;
	    background: white;
	    border-radius: 5px;
	    margin-top: 10px;
	}
	.promoteDlsy p span{
		color: #EE4372;
		font-weight: bold;
		font-size: 1.4rem;
	}
	.promotefxhs{
		background: white;
	    border-radius: 5px;
	    padding: 10px;
	}
	.promotefxhs p{
		margin-bottom: 15px;
	}
	.promotefxhs p:nth-child(1){
		text-align: center;
		font-size: 1.3rem;
		font-weight: bold;
	}
	.promotefxhs p:nth-child(2){
		font-size: 1.1rem;
	}
	.promotefxhs p:nth-child(3){
		color: red;
	}
	.promotefxhs p:nth-child(4){
		color: red;
		font-weight: bold;
	}
	.promotefxhs button{
		width: 90%;
	    margin: 15px auto;
	    display: inherit;
	    height: 50px;
	    font-size: 1.2rem;
	    font-weight: bold;
	}
	.promoteFool{
		background: white;
		border-radius: 5px;
		padding: 5px 5px 28px 5px;
		font-size: 1rem;
	}
	.promoteFool .el-row{
		margin: 30px 0px;
		text-align: center;
	}
	.promoteFool .el-row:nth-child(1){
		font-weight: bold;
	}
	.dialogBodyPron{
		padding: 38px;
	}
	.yqjlPromotes{
		background: white;
		border-radius: 5px;
		padding: 5px 5px 35px 5px;
	}
	.yqjlPromotesTitle{
		text-align: center;
		font-weight: bold;
	}
	.yqjlPromotes .yqjlPromotesData{
		text-align: center;
		margin-top: 21px;
	}
</style>
